import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import faqimg from "../assets/images/galaxy-7040416__480.webp";
import OwlCarousel from "react-owl-carousel-rtl";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { getarticle } from "../actions/axioss/cms.axios.js";
import config from './config/config'
import { MdKeyboardArrowRight } from 'react-icons/md';

export default function Blog() {
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(6)
    const [status, setStatus] = useState(true)
    useEffect(() => {
        getarticles();
    }, [])

    const [blogcard, setblogcard] = useState([])
    const [statuss, setStatuss] = useState([])
    const getarticles = async () => {
        var address = { skip, limit }
        var resp = await getarticle(address);
        console.log("articleaaa", resp);
        if (resp.status) {
            // setblogcard(resp.data)
            setblogcardss(resp.data)
            setSkip(skip + limit)
            setStatus(false)
            setStatuss(resp.data)
            resp?.data?.length != 0 && setblogcard([...blogcard, ...resp.data])
        }
        else {
            setStatus(resp.status)
        }
    }


    const [blogcardss, setblogcardss] = useState([
        {
            "id": 1,
        },
        {
            "id": 2,
        },
        {
            "id": 3,
        },
        {
            "id": 4,
        },
        {
            "id": 5,
        },
        {
            "id": 6,
        },
        {
            "id": 7,
        },
        {
            "id": 8,
        },
    ])

    const options1 = {
        loop: true,
        margin: 20,
        nav: true,
        dots: true,
        smartSpeed: 1200,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }

    return (
        <>

            <Header />

            <div className="blog">
                <section className="tf-page-title style-2" data-aos="fade-right">
                    <div className="tf-container">
                        <div className="row">
                            <div className="col-md-12">

                                <ul className="breadcrumbs">
                                    <li><NavLink to="/">Home <MdKeyboardArrowRight /></NavLink></li>
                                    <li>Blog</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="tf-faq">
                    <div className="tf-container">

                        <div className="blogss pb-3">
                            {blogcard?.length > 0 &&
                                <OwlCarousel className='owl-theme' {...options1}>
                                    {blogcard && blogcard?.map((data, i) =>
                                        <div class='item'>
                                            <div class="card mt-3">
                                                <div className="row align-items-center">
                                                    <div className="col-md-6">
                                                        <img class="card-img-top" src={`${config.IMG_URL}/${data.img}`} alt="Card image cap" />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div class="card-body">
                                                            <h5 class="card-title" >{data?.heading?.length > 50 ? <>{data?.heading.slice(0, 50)}...</> : data?.heading}</h5>
                                                            <p className="grays mb-4">Published By {data.date && `${new Date(data?.date).toLocaleString('default', { month: 'long' })} ${new Date(data?.date).getDate()},${new Date(data?.date).getFullYear()}`}</p>
                                                            <p><p><div
                                                                dangerouslySetInnerHTML={{ __html: data?.content?.length > 100 ? data?.content.slice(0, 300) : data?.content }} />...</p></p>
                                                            <Link to={`/blogdetail/${data?.url}`} state={{ item: data }}><button type="button" className="btn themebtn">View Post</button></Link>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    )}
                                </OwlCarousel>}
                        </div>

                        <div className="row justify-content-left align-items-center pb-3">
                            {status == true ? <>
                                <div className="text-centre">
                                    <h3>Loading...</h3>
                                </div>
                            </>
                                : blogcard && blogcard.map((data, i) =>
                                    <div className="col-md-4 pt-5">
                                        <Link to={`/blogdetail/${data?.url}`} state={{ item: data }}>
                                            <div class="card bottomcard mt-3">
                                                <img class="card-img-top" src={`${config.IMG_URL}/${data.img}`} alt="Card image cap" />
                                                <div class="card-body">
                                                    <h5 class="card-title" >{data.heading.length > 50 ? <>{data.heading.slice(0, 50)}...</> : data.heading}</h5>
                                                    <p className="grays mb-4">Published By {data.date && `${new Date(data?.date).toLocaleString('default', { month: 'long' })} ${new Date(data?.date).getDate()},${new Date(data?.date).getFullYear()}`}</p>
                                                    <p><div
                                                        dangerouslySetInnerHTML={{ __html: data.content.length > 100 ? data.content.slice(0, 300) : data.content }} />...</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )}

                        </div>
                        {statuss?.length == 0 ? "" : <div className="btn-loadmore wow fadeInUp pt-5">
                            {/* <NavLink to="/blog" className="tf-button style-8 loadmore">Load More</NavLink> */}
                            <button onClick={() => getarticles()}>Load More</button>
                        </div>}
                    </div>
                </section >
            </div>

            <Footer />
        </>
    )
}







